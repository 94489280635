import React from 'react';
import {
  OverallContainer,
  PageHeader,
  GSLogo,
  ButtonTag,
  SubContainer,
  MainHeading,
  SUbHeading,
  SvgDiv,
  SubBody,
} from './styles/LoggedInPage.style';
import { ReactComponent as LandingSvg } from '../../assets/background/landingPage.svg';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUserDetails } from '../../utils/API';
import { Login } from '../../constants/routes';

function LoggedIn() {
  const [userDetails, setUserDetails] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [onlyOnce, setOnlyOnce] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    if (token && !onlyOnce) {
      setOnlyOnce(true);
      getUserDetails(token)
        .then((res) => {
          if (res) {
            if (Object.keys(res?.data?.userDetails).length) {
              setUserDetails(res?.data?.userDetails);
              setErrMsg('');
            }
          }
        })
        .catch((err) => {
          setUserDetails({});
          setErrMsg(err?.response?.data?.message);
        });
    }
  }, [token, onlyOnce]);

  const navigate = useNavigate();

  return (
    <OverallContainer>
      <PageHeader>
        <GSLogo>
          <GSLogo.Img src='/gupshupLogo.png' alt='logo loading'></GSLogo.Img>
        </GSLogo>
      </PageHeader>
      <SubContainer>
        <SubBody>
          {Object.keys(userDetails).length ? (
            <>
              <MainHeading>Hi, {userDetails?.name}</MainHeading>
              <SUbHeading>
                You are successfully Logged-in using phone number{' '}
                {userDetails?.phone}
              </SUbHeading>

              <ButtonTag
                onClick={() => {
                  navigate(Login);
                }}
                className='inputTagWidth'
                style={{ fontSize: '16px' }}
              >
                Logout
              </ButtonTag>
            </>
          ) : null}
          {errMsg ? (
            <>
              <MainHeading>{errMsg}</MainHeading>
              <ButtonTag
                onClick={() => {
                  navigate(Login);
                }}
                className='inputTagWidth'
                style={{ fontSize: '16px' }}
              >
                Sign in
              </ButtonTag>
            </>
          ) : null}
        </SubBody>
        <SvgDiv>
          <LandingSvg className='landingSvg' />
        </SvgDiv>
      </SubContainer>
    </OverallContainer>
  );
}

export default LoggedIn;
