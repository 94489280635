import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';

export default function App() {
  return (
    <Router>
      <React.Suspense fallback={'Hello'}>
        <Routes>
          <Route exact path='/' element={<Login />}></Route>
          <Route path='/login' element={<Dashboard />} />
        </Routes>
      </React.Suspense>
    </Router>
  );
}
