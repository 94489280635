import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { v4 } from 'uuid';
import { encrypt } from './utils/cryptoService';

const state = localStorage.getItem('state');
if (!state) localStorage.setItem('state', encrypt(v4()));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
