import React from 'react';
import { FooterContent } from '../../constants/variables';
import {
  FooterFlexBox,
  TheFooterContainer,
  Logo,
  SocialFlexBox,
} from './styles/TheFooter.style';
import { ReactComponent as FacebookSvg } from '../../assets/icons/facebook.svg';
import { ReactComponent as TwitterSvg } from '../../assets/icons/twitter.svg';
import { ReactComponent as InstagramSvg } from '../../assets/icons/instagram.svg';
import { ReactComponent as LInkedInSvg } from '../../assets/icons/linkedIn.svg';

const TheFooter = () => {
  return (
    <TheFooterContainer>
      <div>
        <Logo src='/gupshupLogo.png' alt='logo loading'></Logo>
      </div>
      <FooterFlexBox>
        <div style={{ maxWidth: '400px' }}>{FooterContent}</div>
        <div>
          <SocialFlexBox>
            <FacebookSvg
              onClick={() => {
                window.open('https://www.facebook.com/gupshupbots', '_blank');
              }}
            />
            <InstagramSvg
              onClick={() => {
                window.open(
                  'https://www.instagram.com/gupshupindia/',
                  '_blank'
                );
              }}
            />
            <TwitterSvg
              onClick={() => {
                window.open('https://twitter.com/gupshup', '_blank');
              }}
            />
            <LInkedInSvg
              onClick={() => {
                window.open(
                  'https://www.linkedin.com/company/gupshup',
                  '_blank'
                );
              }}
            />
          </SocialFlexBox>
        </div>
      </FooterFlexBox>
      <div style={{ paddingLeft: '5px' }}>
        Gupshup © 2022. All rights reserved.
      </div>
    </TheFooterContainer>
  );
};

export default React.memo(TheFooter);
