const IV_LENGTH = 16;
const ENCRYPTION_KEY = '6v9y$B&E)H@MbQeThWmZq4t7w!z%C*F-';
const FooterContent =
  'Interact and transact with your consumers through conversational messaging.';

const SERVER_URL = 'https://1clickauth-api.gupshup.io';
const APP_ID = '3aa879d8-a41a-45b2-a6f5-97c08381913a';
const USERNAME = 'Dev_APP';
const PASSWORD = '123456789';

module.exports = {
  SERVER_URL,
  APP_ID,
  FooterContent,
  IV_LENGTH,
  ENCRYPTION_KEY,
  USERNAME,
  PASSWORD,
};
