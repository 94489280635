import React, { useState } from 'react';
import DashBoardLanding from '../../Components/DashBoardLandingPage/DashBoardLandingPage';
import { TheFooter } from '../../containers';
import { LoadingSpinner } from '../../Components/Spinner/Spinner';

export default function Login() {
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading ? <LoadingSpinner /> : null}
      <DashBoardLanding setLoading={setLoading} />
      <TheFooter />
    </>
  );
}
