import LoggedIn from '../../Components/LoggedInPage/LoggedInPage';
import { TheFooter } from '../../containers';

export default function Dashboard() {
  return (
    <>
      <LoggedIn />
      <TheFooter />
    </>
  );
}
