import styled from 'styled-components';

export const TheFooterContainer = styled.div`
  background: #f5edff;
  padding: 1rem 1rem 4rem 3rem;

  @media (max-width: 675px) {
    padding: 1rem 1rem 4rem 1rem;
    font-size: 13px;
  }
`;

export const FooterFlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 5px;

  @media (max-width: 675px) {
    flex-direction: column;
  }

  a {
    color: #4b4b4b !important;
  }
`;

export const Logo = styled.img`
  width: 168px;
  // height: 48px;
  margin: 10px 0;
  @media (max-width: 675px) {
    width: 200px;
  }
`;

export const SocialFlexBox = styled.div`
  display: flex;
  gap: 30px;
  align-items: flex-end;
  justify-content: end;
  padding: 10px 0;

  @media (max-width: 675px) {
    justify-content: start;
  }

  svg:hover {
    cursor: pointer;
  }
`;
