import {
  InputDiv,
  InputLabel,
  InputTag,
  UploadButton,
  UploadDiv,
  InputTextArea,
} from './styles/Input.style';

const InputComponent = ({
  type,
  value,
  onChangeFunc,
  onClickFunc,
  label,
  pattern,
  width,
  height,
  padding,
  margin,
  readOnly,
  labelPosition,
  className,
  labelIndex = null,
  isSetting,
  disabled,
  zIndex,
  id,
  maxLength,
  onBlurFunc = () => {},
  isOnlyText,
}) => {
  return (
    <>
      <InputDiv
        margin={margin}
        width={width}
        height={height}
        className={className}
        autocomplete='off'
      >
        {pattern ? (
          <InputTag
            id={id ? id : labelIndex ? label + labelIndex : label}
            type={type}
            value={value}
            pattern={pattern}
            onChange={(e) => {
              onChangeFunc(e);
            }}
            onClick={(e) => {
              onClickFunc(e);
            }}
            onBlur={(e) => {
              onBlurFunc(e);
            }}
            autocomplete='false'
            name='hidden'
            required
            padding={padding}
            readOnly={readOnly && readOnly}
            disabled={disabled}
          />
        ) : type === 'file' ? (
          <UploadDiv isSetting={isSetting}>
            <InputTag
              className='uploadFile'
              id={id ? id : labelIndex ? label + labelIndex : label}
              type='text'
              value={value}
              onChange={(e) => {
                onChangeFunc(e);
              }}
              onBlur={(e) => {
                onBlurFunc(e);
              }}
              autocomplete='false'
              name='hidden'
              required
              padding={padding}
              readOnly={readOnly && readOnly}
            />
            <UploadButton
              onClick={(e) => {
                document.getElementById(label).focus();
              }}
              isSetting={isSetting}
            >
              {'Browse'}
              <InputTag
                id={id ? id : labelIndex ? label + labelIndex : label}
                style={{ display: 'none' }}
                type={type}
                accept='.png,.jpeg,.svg'
                name='browse'
                value={isOnlyText ? '' : value}
                onChange={(e) => {
                  onChangeFunc(e);
                }}
                onBlur={(e) => {
                  onBlurFunc(e);
                }}
                autocomplete='false'
                required
                padding={padding}
              />
            </UploadButton>
          </UploadDiv>
        ) : type === 'number' ? (
          <InputTag
            id={id ? id : labelIndex ? label + labelIndex : label}
            type='text'
            value={value}
            onChange={(e) => {
              if (!isNaN(e.target.value)) {
                onChangeFunc(e);
              }
            }}
            onBlur={(e) => {
              onBlurFunc(e);
            }}
            pattern='[0-9]'
            autocomplete='false'
            name='hidden'
            required
            padding={padding}
            readOnly={readOnly && readOnly}
            disabled={disabled}
            maxLength={maxLength}
          />
        ) : type === 'textarea' ? (
          <InputTextArea
            id={id ? id : labelIndex ? label + labelIndex : label}
            type={type}
            value={value}
            onChange={(e) => {
              onChangeFunc(e);
            }}
            onBlur={(e) => {
              onBlurFunc(e);
            }}
            autocomplete='false'
            name='hidden'
            required
            readOnly={readOnly && readOnly}
            disabled={disabled}
          />
        ) : (
          <InputTag
            id={id ? id : labelIndex ? label + labelIndex : label}
            type={type}
            value={value}
            onChange={(e) => {
              onChangeFunc(e);
            }}
            onBlur={(e) => {
              onBlurFunc(e);
            }}
            autocomplete='false'
            name='hidden'
            required
            padding={padding}
            readOnly={readOnly && readOnly}
            disabled={disabled}
            maxLength={maxLength}
          />
        )}
        <InputLabel
          labelPosition={labelPosition}
          value={value}
          type={type}
          autocomplete='false'
          name='hidden'
          zIndex={zIndex}
          onClick={(e) => {
            document
              .getElementById(labelIndex ? label + labelIndex : label)
              .focus();
          }}
          onBlur={(e) => {
            onBlurFunc(e);
          }}
        >
          {label}
        </InputLabel>
      </InputDiv>
    </>
  );
};
export default InputComponent;
