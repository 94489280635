import React from 'react';
import InputComponent from '../Input/Input';
import {
  OverallContainer,
  PageHeader,
  GSLogo,
  ButtonTag,
  SubContainer,
  MainHeading,
  SUbHeading,
  SvgDiv,
  SubBody,
  FlexBox,
  RelativeTag,
  OrText,
  HorizontalLine,
  GoogleButton,
} from './styles/DashBoardLandingPage.style';
import { signInWithWhatsapp } from '../../utils/whatsapp';
import { ReactComponent as LandingSvg } from '../../assets/background/landingPage.svg';
import { ReactComponent as WhatsappLogo } from '../../assets/icons/whatsapp.svg';

function DashBoardLanding({ setLoading }) {
  return (
    <OverallContainer>
      <PageHeader>
        <GSLogo>
          <GSLogo.Img src='/gupshupLogo.png' alt='logo loading'></GSLogo.Img>
        </GSLogo>
      </PageHeader>
      <SubContainer>
        <SubBody>
          <MainHeading>
            Authenticate your customers in just a few seconds.
          </MainHeading>
          <SUbHeading>
            Make your onboarding fast - with better user experience and,
            gauranteed customer optins.
          </SUbHeading>
          <FlexBox isSignUp={true}>
            <InputComponent
              inputClassName='form-control inputTagWidth'
              type='text'
              width='55%'
              value={''}
              onChangeFunc={(e) => {}}
              label={'Email or Phone'}
              readOnly={true}
            />
            <ButtonTag
              onClick={() => alert('Please Sign in with whatsapp')}
              className='inputTagWidth'
              style={{ fontSize: '16px' }}
            >
              Get started today
            </ButtonTag>
          </FlexBox>

          <RelativeTag>
            <HorizontalLine></HorizontalLine>
            <OrText>or</OrText>
          </RelativeTag>
          <GoogleButton
            onClick={() => {
              setLoading(true);
              signInWithWhatsapp();
            }}
          >
            <WhatsappLogo
              style={{
                height: 'auto',
                width: '18px',
                marginRight: '7px',
              }}
            ></WhatsappLogo>
            Sign in with Whatsapp
          </GoogleButton>
        </SubBody>
        <SvgDiv>
          <LandingSvg className='landingSvg' />
        </SvgDiv>
      </SubContainer>
    </OverallContainer>
  );
}

export default DashBoardLanding;
