import styled from 'styled-components';
export const GSLogo = styled.div`
  //   display: flex;
  //   z-index: 10;
  //   @media only screen and (max-width: 650px) {
  //     flex: 0 0 10vh;
  //   }
  @media only screen and (max-width: 400px) {
    ${({ isRightSide }) =>
      isRightSide &&
      `display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: auto;`}
  }
  @media only screen and (max-width: 575px) {
    ${({ isRightSide }) => isRightSide && `display:none;`}
  }
`;

export const PageHeader = styled.div`
  font-family: var(--default-font-family-dashboard);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 3rem 1rem 0rem 3rem;
  z-index: 10;
  @media (max-width: 675px) {
    padding: 1rem 1rem 0rem 1rem;
  }
`;

export const TalkSales = styled.a`
  letter-spacing: 0.12px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #5f36e5 !important;
  padding: 0 10px;
  opacity: 1;
`;

export const ButtonTag = styled.button`
  background: #5f36e5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  margin-bottom: 20px;
  color: #fff;
  font-size: 15px;
  padding: 15px 24px;
  font-weight: 600;
  border: none;
`;

GSLogo.Img = styled.img`
  width: 168px;
  // height: 48px;
  margin: 10px 0px;
  @media (max-width: 675px) {
    width: 200px;
  }
  // @media (max-width: 575px) {
  //   margin: 20% 0%;
  // }
`;

export const OverallContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: poppins;
  // height: 100vh;
  overflow: hidden;
  min-height: 80vh;

  @media (max-width: 600px) {
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
export const SvgDiv = styled.div`
  width: 50%;
  .landingSvg {
    position: relative;
    top: -10%;
    right: -5%;
    height: calc(100vh - 20px);
  }
  @media (max-width: 675px) {
    display: none;
  }
`;
export const SubContainer = styled.div`
  //   display: grid;
  //   grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
  padding: 0rem 1rem 0rem 3rem;
  font-family: var(--default-font-family-dashboard);
  @media (max-width: 675px) {
    padding: 1rem 1rem 0rem 1rem;
  }
`;
export const SubBody = styled.div`
  //   flex: 2;
  margin-top: 3rem;
  max-width: 500px;
  text-align: center;
  @media (max-width: 767px) {
    margin-top: 0rem;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;
  padding-top: 45px;
  @media (max-width: 767px) {
    justify-content: center;
    display: block;
  }
  .inputTagWidth {
    ${({ isSignUp }) => isSignUp && 'height: 100%;'}
    ${({ isSignUp }) =>
      isSignUp &&
      `max-height: 48px;
  max-width:230px;
  padding: 15px 15px;`}
  }
  @media (max-width: 675px) {
    .inputTagWidth {
      width: 100% !important;
      //   min-width: 280px;
      margin: 10px;
    }
  }
  @media (max-width: 575px) {
    .inputTagWidth {
      margin-top: 10%;
      margin-bottom: 5%;
    }
  }
  .selected-flag {
    ${({ isSignUp }) => isSignUp && 'height: 45px;'}
  }
`;

export const RelativeTag = styled.div`
  position: relative;
  text-align: center;
  padding: 0 10px;
  width: 100%;
`;

export const HorizontalLine = styled.div`
  border-bottom: 0.5px solid;
  opacity: 0.2;
`;

export const OrText = styled.div`
  position: absolute;
  top: -13px;
  background-color: white;
  left: 50%;
  color: #464646;
`;

export const MainHeading = styled.div`
  text-align: left;
  font-weight: 600;
  font-size: 32px;
  ${({ isSignIn }) => !isSignIn && 'letter-spacing:-1px;'}
  ${({ isSignIn }) => !isSignIn && 'line-height:1;'}
  padding: 10px 0;
  color: #4b4b4b;
  opacity: 1;
  ${({ isSignIn }) => isSignIn && 'font-size:40px;'}
  @media (max-width: 675px) {
    font-size: 20px;
  }
  @media (max-width: 575px) {
    width: 65%;
    line-height: 1.5;
  }
  @media (max-width: 375px) {
    width: 75%;
    line-height: 1.5;
  }
`;
export const SigninHeading = styled.div`
  text-align: left;
  font-weight: 600;
  font-size: 32px;
  letter-spacing: -1px;
  line-height: 1;
  padding: 10px 0;
  color: #4b4b4b;
  opacity: 1;
  font-size: 40px;
  @media (max-width: 675px) {
    font-size: 28px;
    text-align: center;
    line-height: 42px;
  }
  @media (max-width: 575px) {
    width: 65%;
    text-align: center;
  }
  @media (max-width: 375px) {
    width: 75%;
    text-align: center;
  }
`;
export const IntlPhoneInput = styled.div`
  .intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
    background-color: transparent;
  }
`;

export const SUbHeading = styled.div`
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #4b4b4b;
  opacity: 1;
  padding: 10px 0;
  @media (max-width: 675px) {
    font-size: 14px;
  }
  @media (max-width: 575px) {
    width: 65%;
    line-height: 1.5;
    font-size: 13px;
  }
  @media (max-width: 375px) {
    width: 70%;
    line-height: 1.5;
  }
`;

export const GoogleButton = styled.button`
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  margin: 20px 0;
  padding: 10px 20px;
  font-size: 15px;
  color: #8d8d8d;
  width: max-content;
  border: 1px solid #e3e3e3;
  @media only screen and (max-width: 575px) {
    margin-top: 10%;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const OverallGroup = styled.div`
  display: grid;
  width: 100%;
  height: 50vh;
  background-color: #f5f5f5;
  grid-template-columns: repeat(auto-fit, minmax(315px, 1fr));
  grid-template-areas: 'gifContainer loginContainer';
  @media only screen and (max-width: 650px) {
    display: block;
    height: auto;
  }
`;
OverallGroup.Sub = styled.div`
  z-index: 6;
  position: relative;
  left: 5%;
  min-width: 40%;
  top: 5%;
  height: inherit;
  @media only screen and (max-width: 650px) {
    position: static;
    margin: 0 10px;
  }
`;
export const AlreadyRegisteredText = styled.p`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  margin-top: 24px;
  margin-bottom: 40px;

  @media (max-width: 575px) {
    margin-top: 0px;
  }

  span {
    opacity: 0.5;
    color: #4b4b4b;
    margin-right: 5px;
    font-weight: 600;
  }

  a {
    opacity: 1;
    color: #5f36e5;
    font-weight: 600;
    font-size: 15px;
  }

  @media (min-width: 600px) {
    display: none;
  }
`;

export const GIPBotIFrame = styled.iframe`
  width: 100%;
  max-width: 350px;
  margin: 60px auto auto;
  height: calc(100% - 100px);
  border: none;
  border-radius: 12px;
  display: flex;
  position: fixed;
  z-index: 11 !important;
  bottom: 87px;
  box-shadow: rgb(0 0 0 / 40%) 0px 6px 25px 0px, rgb(0 0 0 / 10%) 0px 1px 2px 0px, rgb(255 175 50 / 50%) 0px 2px 0px 0px inset;
  right: 0;
}
  right: 20px;
  @media only screen and (max-width: 650px) {
    left: 0%;
  }
`;
export const Rocketchat = styled.div`
  border: 1px solid #ffaf32;
  width: 100%;
  max-width: 55px;
  position: fixed;
  height: 100%;
  right: 20px;
  bottom: 15px;
  z-index: 9;
  background: #ffaf32;
  max-height: 55px;
  border-radius: 33px;
  cursor: pointer;
  .chatIcon {
    position: absolute;
    right: 13px;
    bottom: 10px;
    path {
      fill: rgb(255, 255, 255);
    }
  }
  .downIcon {
    position: absolute;
    right: 17px;
    bottom: 13px;
    width: 20px;
    path {
      fill: rgb(255, 255, 255);
    }
  }
`;
export const OverallSubGroup = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 10px;
  max-width: 400px;
`;
export const MainContainer = styled.div`
  min-width: 100px;
  max-width: 400px;
  height: 400px;
  border-radius: 10px;
  border: 1px solid rgba(220, 220, 220, 0.5);
  background-color: #ffffff;
`;
MainContainer.Sub = styled.div`
  padding: 0px 30px 50px 30px;
`;

export const VerifyContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 62vh;
  height: 400px;
  border-radius: 10px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 10px;
  background-color: #ffffff;
`;
export const PhoneNumber = styled.h1`
  font-size: small;
  color: rgba(111, 111, 111, 0.8);
  text-align: start;
`;
export const NavLink = styled('./signup')``;
export const WelcomeText = styled.h2`
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
`;
export const TabContainer = styled.div`
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  margin-bottom: ${(props) => (props.showVerify ? '30px' : '50px')};
`;
TabContainer.Tab1 = styled.div`
  border-top: ${(props) =>
    props?.selectedTab === 0 ? '4px solid #1565f8' : 'none'};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  border-top-left-radius: 10px;
`;
TabContainer.Tab2 = styled.div`
  border-top: ${(props) =>
    props?.selectedTab === 1 ? '4px solid #1565f8' : 'none'};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  border-top-right-radius: 10px;
`;

TabContainer.Button1 = styled.button`
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 18px;
  color: ${(props) => (props.selectedTab !== 0 ? '#838383' : '#000000')};
`;
TabContainer.Button2 = styled.button`
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 18px;
  color: ${(props) => (props.selectedTab !== 1 ? '#838383' : '#000000')};
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 20%;
  width: 100%;
`;
export const ButtonContainer = styled.div`
  width: 100%;
  text-align: end;
  height: 20%;
  margin-bottom: ${(props) => (props.showVerify ? '0px' : '30px')};
`;
ButtonContainer.Button = styled.div`
  border-radius: '50px';
  width: '33%';
`;
export const LoginWith = styled.h5`
  cursor: pointer;
`;
export const HorizontalRule = styled.hr`
  width: 30%;
  height: 2px;
  border-radius: 10px;
  border: none;
  background-color: rgba(211, 211, 211, 0.5);
`;
export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 80%;
`;
export const ForgotPassword = styled.h4`
  cursor: pointer;
`;
export const StyledInput = styled.input`
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 2rem;
  width: 80%;
  height: 3rem;
  border: none;
  outline: none;
  color: #3c354e;
  font-size: 1rem;
  font-weight: bold;
  &:focus {
    display: inline-block;
    box-shadow: 0 0 0 0.2rem #b9abe0;
    backdrop-filter: blur(12rem);
    border-radius: 2rem;
  }
  &::placeholder {
    color: #b9abe099;
    font-weight: 100;
    font-size: 1rem;
  }
`;
export const PhoneInput = styled.div`
  height: 35px;
  width: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  :focus & {
    border-color = red !important;
}
`;
PhoneInput.numberInput = styled.input`
:focus & {
    border-color = red !important;
}`;

export const Seperator = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const FooterText = styled.div`
  width: 100%;
  height: 20%;
  background-color: rgb(45, 73, 89);
  color: white;
  font-size: medium;
  font-weight: 500;
  font-style: normal;
  @media only screen and (max-width: 320px) {
    position: relative;
    width: 100%;
    height: 10%;
    h1 {
      font-size: medium;
      font-weight: 500;
      font-style: normal;
    }
  }
  @media only screen and (min-width: 360px) {
    position: relative;
    width: 100%;
    height: 40vh;
    h1 {
      font-size: large;
      font-style: normal;
    }
  }
  @media only screen and (min-width: 411px) {
    width: 100vw;
    height: 31vh;
    h1 {
      font-size: medium;
      font-weight: 500;
      font-style: normal;
    }
  }
  @media only screen and (min-width: 768px) {
    width: 100%;
    position: initial;
    height: 29%;
    h1 {
    }
  }
  @media only screen and (min-width: 1024px) {
    width: 100%;
    // position: initial;
    height: 29%;
    h1 {
    }
  }
  @media only screen and (min-width: 1280px) {
    width: 100%;
    position: initial;
    height: 32%;
    h1 {
    }
  }
`;
export const FooterText1 = styled.div`
  background-color: rgb(45, 73, 89);
  color: white;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  width: 100%;
  height: 25%;
  display: grid;
  grid-template-columns: 50%;
  grid-template-areas: 'text .';
  position: relative;
  z-index: 5;
  @media only screen and (max-width: 850px) {
    font-size: 14px;
    padding-left: 20px;
  }
  @media only screen and (max-width: 650px) {
    display: block;
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
  }
  @media only screen and (min-width: 1367px) {
    height: 32%;
  }
`;
FooterText1.H1 = styled.h1`
  margin: 0;
  height: 100%;
  text-align: start;
  grid-area: text;
  position: relative;
  top: 50%;
  height: fit-content;
  transform: translateY(-50%);
  padding: 0 20px;
  font-size: 30px;
  @media only screen and (max-width: 850px) {
    top: 50%;
  }
  @media only screen and (max-width: 650px) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
`;
export const FooterText2 = styled.div`
  width: 100%;
  height: 55%;
  background-color: #f5f5f5;
  color: black;
  font-size: medium;
  font-weight: 500;
  font-style: normal;
`;
export const FooterText3 = styled.div`
  width: 100%;
  height: 34%;
  background-color: rgb(45, 73, 89);
  color: white;
  font-size: medium;
  font-weight: 500;
  font-style: normal;
  h1 {
  }
`;
export const OnBoardText = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: '. OBText';
  margin: 50px 0 30px 5%;
  height: 10vh;
  @media only screen and (max-width: 650px) {
    display: block;
    height: auto;
    margin: 0 0 20px 5%;
  }
`;
OnBoardText.H1 = styled.h1`
  text-align: start;
  grid-area: OBText;
  letter-spacing: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 54px;
  color: #1e6bde;
  margin: 0;
  @media only screen and (max-width: 800px) {
    font-size: 35px;
  }
  @media only screen and (max-width: 650px) {
    line-height: 40px;
    font-size: 30px;
  }
`;
export const ResendVerifyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
`;
export const StoreGif = styled.img`
  position: relative;
  min-width: 300px;
  max-width: 600px;
  width: 100%;
  top: 20%;
  height: auto;
  left: 50%;
  grid-area: gifContainer;
  transform: translate(-50%, -50%);
  @media only screen and (max-width: 650px) {
    max-width: 400px;
    transform: none;
    position: static;
    display: none;
  }
  // @media only screen and (max-width: 320px) {
  //   width: auto;
  //   // height: 45vh;
  // }
  // @media only screen and (min-width: 360px) {
  //   width: auto;
  //   // height: 45vh;
  // }
  // @media only screen and (min-width: 411px) {
  //   width: auto;
  //   // height: 44vh;
  // }
  // @media only screen and (min-width: 768px) {
  //   width: auto;
  //   // height: 65vh;
  // }
  // @media only screen and (min-width: 1024px) {
  //   width: auto;
  //   // height: 65vh;
  // }
  // @media only screen and (min-width: 1280px) {
  //   width: auto;
  //   // height: 65vh;
  // }
`;
