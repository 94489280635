import styled from 'styled-components';
import { isMobileOrTablet } from '../../../utils/common';

export const InputDiv = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width : '350px')};
  margin-bottom: 15px;
  ${({ margin }) => margin && `margin:${margin};`}
  ${({ height }) => height && 'height: 50px;'}
  .border-red {
    border-color: red !important;
    outline: none;
  }

  @media (max-width: 350px) {
    width: 90% !important;
    margin-bottom: -18px !important;
  }

  @media (max-width: 400px) {
    width: 90% !important;
    margin-bottom: -18px !important;
  }
  @media (max-width: 575px) and (min-width: 401px) {
    width: 90% !important;
    margin-bottom: -18px !important;
  }
  @media (max-width: 320px) {
    width: 90% !important;
    margin-bottom: -18px !important;
  }
`;

export const InputLabel = styled.label`
  position: absolute;
  cursor: text;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : '0')} !important;
  top: ${({ type, value }) => (type && value ? '-5px' : '12px')};
  left: ${({ labelPosition }) => (labelPosition ? '30px' : '10px')};
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  padding: 0 10px;
  margin-left: 7px;
  color: #999;
  transition: all 0.3s ease;
  ${({ value, type }) =>
    value && type
      ? ` font-size: 11px;
  border: none;
  border-color: blue !important;
  top: -5px;
  box-sizing: none;`
      : ''}
  .border-red {
    border-color: red !important;
    outline: none;
  }
`;

export const InputTag = styled.input`
  display: block;
  width: 100%;
  height: 48px;
  background: transparent;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  opacity: 1;
  transition: all 0.3s ease;
  padding: ${({ padding }) => (padding ? padding : '0 15px')};
  font-size: 14px;
  font-family: var(--default-font-family-dashboard);

  &:focus {
    border-color: blue !important;
    outline: none;
  }

  &:focus + ${InputLabel}, &:valid + ${InputLabel}, ${InputLabel}&:focus {
    font-size: 11px;
    border: none;
    border-color: blue !important;
    top: -5px;
    box-sizing: none;
    z-index: 1;
  }

  &:focus + ${InputLabel} {
    color: blue;
    border-color: blue !important;
  }
  .border-red {
    border-color: red !important;
    outline: none;
  }
`;

export const InputTextArea = styled.textarea`
  display: block;
  width: 100%;
  height: 180px;
  ${() => isMobileOrTablet() && 'height:80px;'}
  resize: vertical;
  max-height: 200px;
  background: transparent;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  opacity: 1;
  transition: all 0.3s ease;
  padding: 10px;

  &:focus {
    border-color: blue !important;
    outline: none;
  }

  &:focus + ${InputLabel}, &:valid + ${InputLabel}, ${InputLabel}&:focus {
    font-size: 11px;
    border: none;
    border-color: blue !important;
    top: -5px;
    box-sizing: none;
    z-index: 1;
  }

  &:focus + ${InputLabel} {
    color: blue;
    border-color: blue !important;
  }
  .border-red {
    border-color: red !important;
    outline: none;
  }
`;

export const UploadDiv = styled.div`
  position: relative;
  width: 100%;
  ${({ isSetting }) => isSetting && 'height:40px;'}
`;

export const UploadButton = styled.label`
  position: relative;
  border-radius: 5px;
  z-index: 2;
  height: ${({ isSetting }) => (isSetting ? '80%;' : '90%;')}
  cursor: pointer;
  color: #5f36e5;
  background-color: #fbf8ff;
  border: 1px solid #5f36e5;
  top: ${({ isSetting }) => (isSetting ? '2%;' : '50%;')}
  left: 100%;
  font-size: 12px;
  padding: 5px;
  font-weight: 600;
  margin-top:-35px;
  transform: translate(-110%, -115%);
  @media (max-width:400px) and (min-width: 360px){
    left:100%;
  }
  @media (max-width:575px) and (min-width: 401px){
    left:100%;
  }
  @media (max-width:320px){
    left:100%;
  }
`;
