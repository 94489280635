import { POST } from '../lib/axios';
import { APP_ID, PASSWORD, USERNAME } from '../constants/variables';
import { decrypt } from './cryptoService';

async function signIn() {
  return new Promise((resolve, reject) => {
    const header = {
      'Access-Control-Allow-Origin': '*',
    };
    POST(
      `v1/auth/${APP_ID}/signIn`,
      {
        username: USERNAME,
        password: PASSWORD,
      },
      header
    )
      .then((res) => resolve(res.data.data.token))
      .catch((err) => reject(err));
  });
}

export async function getUrl() {
  const TOKEN = await signIn();
  const state = localStorage.getItem('state');
  return new Promise((resolve, reject) => {
    const header = {
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + TOKEN,
    };
    POST(
      `v1/whatsapp/${APP_ID}/getUrl`,
      {
        state: decrypt(state),
        source: 'web',
      },
      header
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

export async function getUserDetails(token) {
  const TOKEN = await signIn();
  const state = localStorage.getItem('state');
  return new Promise((resolve, reject) => {
    const header = {
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + TOKEN,
    };
    POST(
      `v1/whatsapp/${APP_ID}/${token}/getUserDetails`,
      {
        state: decrypt(state),
      },
      header
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
