import axios from 'axios';
import { SERVER_URL } from '../constants/variables';

axios.defaults.baseURL = SERVER_URL;
axios.defaults.headers = {
  'Content-Type': 'application/json',
};

export const GET = function (url, headers = null) {
  //axios.defaults.headers['gipuserid'] = jsCookie.get('gipuserid');
  if (headers && headers.Authorization) {
    axios.defaults.headers['Authorization'] = headers.Authorization;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => resolve(response))
      .catch(function (error) {
        reject(error);
      });
  });
};

export const POST = function (url, data = {}, headers = null) {
  return new Promise((resolve, reject) => {
    // convert to string if it's object
    try {
      if (typeof data !== 'string') {
        data = JSON.stringify(data);
      }
    } catch (error) {
      reject(error);
    }

    if (headers && headers.Authorization) {
      axios.defaults.headers['Authorization'] = headers.Authorization;
    }
    //axios.defaults.headers['gipuserid'] = jsCookie.get('gipuserid');
    axios
      .post(url, data, headers)
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error);
      });
  });
};
